import { PUBLIC_ENVIRONMENT } from '$env/static/public';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getAuth, GoogleAuthProvider } from 'firebase/auth';

// From https://firebase.google.com/docs/projects/api-keys:
// Usually, you need to fastidiously guard API keys
// (for example, by using a vault service or setting the keys as environment variables);
// however, API keys for Firebase services are ok to
// include in code or checked-in config files.

const localFirebaseConfig = {
	apiKey: 'AIzaSyCOQkk8IxXIWDw5uYMWmjt_oi6L-kFibJ4',
	// authDomain: 'voltaic-nebula-360300.firebaseapp.com',
	authDomain: 'auth-dev.iliad.ai',
	projectId: 'voltaic-nebula-360300',
	storageBucket: 'voltaic-nebula-360300.appspot.com',
	messagingSenderId: '114296571634',
	appId: '1:114296571634:web:91bcf4de406b051ac3b26b',
	measurementId: 'G-MXYYXYG1L3'
};

const prodFirebaseConfig = {
	apiKey: 'AIzaSyC5fbUS4U9VGQqVhRbCn82qbIv4-fQAfM0',
	// authDomain: 'iliad-production.firebaseapp.com',
	authDomain: 'auth.iliad.ai',
	projectId: 'iliad-production',
	storageBucket: 'iliad-production.appspot.com',
	messagingSenderId: '820767175720',
	appId: '1:820767175720:web:721fa6907446e63001f11d',
	measurementId: 'G-FEDT22RSME'
};

const firebaseConfig =
	PUBLIC_ENVIRONMENT === 'prod'
		? prodFirebaseConfig
		: PUBLIC_ENVIRONMENT === 'local'
		? localFirebaseConfig
		: undefined;

if (firebaseConfig == undefined) {
	throw new Error('No firebase config for environment: ' + PUBLIC_ENVIRONMENT);
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const googleProvider = new GoogleAuthProvider();
